.icon {
  display: inline-block;
  width: 24px;
  height: 24px;
  mask-size: cover;
}
.icon-arrow-down {
  background: black;
  mask-image: url("../assets/icons/arrow-down.svg");
}
.icon-close {
  background: black;
  mask-image: url("../assets/icons/close.svg");
}
.icon-plus {
  mask-image: url("../assets/icons/plus.svg");
}
.icon-lamp {
  mask-image: url("../assets/icons/lamp.svg");
}
.icon-file {
  mask-image: url("../assets/icons/file.svg");
}
.icon-trash {
  mask-image: url("../assets/icons/trash.svg");
}
.icon-edit {
  mask-image: url("../assets/icons/edit.svg");
}
.icon-check {
  mask-image: url("../assets/icons/check.svg");
}
