.icon-active {
  transform: rotate(180deg);
  animation-name: rotate;
  animation-duration: 500ms;
}
.icon-inactive {
  transform: rotate(0deg);
  animation-name: rotate-reverse;
  animation-duration: 500ms;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(180deg);
  }
}
@keyframes rotate-reverse {
  from {
    transform: rotate(180deg);
  }
  to {
    transform: rotate(0deg);
  }
}
