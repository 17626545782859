.loader {
  display: inline-block;
  width: 1em;
  height: 1em;
  color: inherit;
  vertical-align: middle;
  pointer-events: none;
  border: 0.2em solid transparent;
  border-top-color: #def545;
  border-radius: 50%;
  animation: 1s loader-animation linear infinite;
  position: relative;
}
.loader:before {
  content: "";
  display: block;
  width: inherit;
  height: inherit;
  position: absolute;
  top: -0.2em;
  left: -0.2em;
  border: 0.2em solid #def545;
  border-radius: 50%;
  opacity: 0.5;
}

@keyframes loader-animation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
